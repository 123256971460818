import styled from "@emotion/styled";
export const StyledSpinnerComponent = styled.svg`
  transition-property: transform;
  animation-name: __svg-spinner_infinite-spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;

  @keyframes __svg-spinner_infinite-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
