import styled from "@emotion/styled";
import { MAX_DESKTOP_CONTENT_WIDTH } from "constants/vp-sizes";
export const StyledBaseLayoutSegmentComponent = styled.div`
  box-sizing: content-box;

  .segment-content-container {
    &:not(.full-width) {
      max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px;
    }

    margin: auto;
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px) {
    padding: 0 24px;
  }
`;
